/** Media Queries **/
@mixin telefono {
    @media (min-width: #{$telefono}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}
@mixin grid($columnas, $espaciado ) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}

@mixin boton($bgColor, $display ) {
    background-color: $bgColor;
    color: $blanco;
    font-weight: $bold;
    font-family: $fuente_principal;
    text-decoration: none;
    text-transform: uppercase;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: 2rem;
    display: $display;
    border: none;
    width: auto;

    &:hover {
        background-color: darken($bgColor, 5);
        cursor: pointer;
    }
}