.avisoPrivacidad {
    h1, h4 {
        color: $azul;
    }
    p {
        width: 100%;
        margin-top: 0;
        text-align: left;
    }
    ol {
        width: 100%;
        margin: 0 0 2rem 0;
    }
}