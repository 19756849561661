.alerta {
    padding: .5rem;
    text-align: center;
    color: $blanco;
    font-weight: $bold;
    text-transform: uppercase;
    margin: 1rem 0;
    background-color: $azul;

    &.error {
        background-color: $rojo;
    }

    &.exito {
        background-color: $verde;
    }
}
