.carousel-container  {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    position: relative;

    height: 100vh;
    overflow: hidden;
}

.carousel-image, .carouselM-image {
    position: absolute;
    top: 0;
    transition: opacity 0.5s ease-in-out;
    object-fit: cover;

    @include tablet {
        width: 100%;
    }
}

.carousel-testimonialsContainer {
    width: 30rem;
    height: 30rem;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    @include tablet {
        width: 40rem;
        height: 40rem;        
    }
}

.carousel-testimonialsWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-testimonio {
    width: 100%;
    flex: 0 0 auto;
    text-align: center;
    background-color: $blanco;
    padding: 20px;
}

.carousel-controlPrev, .carousel-controlNext {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
}
.carousel-controlPrev {
    left: 0;
}

.carousel-control {
    cursor: pointer;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}