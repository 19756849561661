.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    padding: $separacion;
    background-color: $negroClaro;
    margin-bottom: 0;

    h4 {
        font-weight: 600;
        text-transform: uppercase;
    }

    @include tablet {
        align-items: flex-end;
        display: grid;
        grid-template-columns: 1fr 2fr 2fr; /* Three equal-width columns */
        gap: 2rem; /* Gap between grid items */
    }

    .redes {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        gap: 2rem;
    }

    .logo {
        width: 100%;
    }

    .boton-WA {
        margin: 0 0 2rem 0;
    }

    .footer-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
            text-align: center;
        }
        
        .fa-brands {
            font-size: 2.5rem;
            margin-left: 1rem;
        }

        .fa-phone {
            margin-left: 1rem;
        }
        .fa-envelope {
            margin-right: 1rem;
        }
    }

    .sedes {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
            gap: 20px; /* Gap between grid items */
        }

        .sedes-item {
            display: flex;
            flex-direction: row;
            @include tablet {
                flex-direction: column;
            }
        }
    }
}