.boton-azul {
    @include boton($azulClaro, inline-block);
}

.boton-WA {
    @include boton($verdeWA, inline-block);
}

.boton-categoria {
    display: inline-block;
    margin: 1rem 0;
    padding: 1rem 2rem;
    text-decoration: none;
    color: $gris;
    border: 2px solid $azul; /* Border style */
    border-radius: 2rem;
    font-size: 1.2rem;
    text-transform: lowercase;
}