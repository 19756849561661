.seccion {
    overflow: hidden;
}

.seccion .animate {
    opacity: 0;
    transition: 1s;
}

.seccion.show-animate .animate {
    opacity: 1;
    transition: 1s;
}

.sec-1 .animate {
    transform: translateX(-200%);
    transition-delay: calc(0.2s * var(--i));
}

.sec-1.show-animate .animate {
    transform: translateX(0);
}

.sec-2 .animate {
    transform: translateX(100%);
}

.sec-2.show-animate .animate {
    transform: translateX(0);
}

.sec-3 .animate {
    transform: scale(0.5);
}

.sec-3.show-animate .animate {
    transform: scale(1);
}

.sec-31 .animate {
    transform: scale(0.3);
}

.sec-31.show-animate .animate {
    transform: scale(1);
}

.sec-4 .showRight-animate {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $blanco;
    transition: 1s;
}

.sec-4.show-animate .showRight-animate {
    width: 0;
}

.sec-5 .animate-logos img {
    transform: translateX(-1000%);
    transition-delay: calc(0.16s * var(--i));
}

.sec-5.show-animate .animate-logos img {
    transform: translateX(0);
}

.sec-6 .animate-tarjetas {
    opacity: 0;
    transition: opacity 1s ease-in-out 0.5s;
    transition-delay: calc(0.24s * var(--i));
}

.sec-6.show-animate .animate-tarjetas {
    opacity: 1;
}