.estructuras-hero, .ac-hero, .geotecnia-hero, .electrom-hero, .paneles-hero,
.paneles-contacto, .inm-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    @include desktop {
        background-attachment: fixed;
    }
}

.estructuras-hero, .ac-hero, .geotecnia-hero, .electrom-hero, .paneles-hero, .inm-hero {
    .contenedor {
        display: flex;
        flex-direction: column;
        p {
            background-color: $transparente-negroF;
            padding: 1rem 2rem;
        }
        .grid-item {
            max-width: 100%;
            overflow: hidden;
        }

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr; /* Three equal-width columns */
            gap: 10rem;
        }
    }
}

.estructuras-hero {
    background-image: url(/build/img/estructurasHero.webp);
}

.ac-hero {
    background-image: url(/build/img/ac-hero.webp);
    h3 {
        font-size: 3.5rem;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
        @include tablet {
            font-size: 5rem;
        }
    }
}

.geotecnia-hero {
    background-image: url(/build/img/geotecnia-hero.webp);
}

.electrom-hero {
    background-image: url(/build/img/electromHero.webp);
}

.paneles-hero {
    background-image: url(/build/img/panelesHero.webp);
    background-position: bottom;
}
.paneles-contacto {
    background-image: url(/build/img/paneles1.webp);
    .contenedor {
        h3 {
            font-size: 3.5rem;
            text-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
            @include tablet {
                font-size: 5rem;
            }
        }
        p {
            background-color: $blanco;
            color: $azul;
            border-radius: 5rem;
            padding: 4rem;
            max-width: 100%;
            @include tablet {
                max-width: 50%;
            }
        }
        .fa-brands {
            font-size: 2.5rem;
            margin-left: 1rem;
        }
    }
}

.inm-hero {
    background-image:url(/build/img/inmHero.webp)
}