.contacto {
    picture {
        max-width: 80rem;
        margin-bottom: 2rem;
    }

    .contenedor {
        border-radius: 5rem;
        background-color: $blancoOscuro;
        width: 90%;
        @include tablet {
            width: 50%;
            padding: 4rem;
        }
    }
}

.formulario {
    width: 95%;
    @include tablet {
        width: 80%;
    }
    
    p {
        font-size: 1.4rem;
        color: $grisClaro;
        margin: 2rem 0 0 0;
    }

    legend {
        font-size: 2rem;
        color: $azul;
    }

    label {
        text-transform: uppercase;
        display: block;
        color: $azul;
    }

    input:not([type="submit"]), 
    textarea,
    select {
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: $blanco;
        border: 1px solid $azul;
        border-radius: 1rem;
        margin-bottom: 2rem;
    }

    textarea {
        height: 10rem;
    }

    .forma-contacto {
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    input[type="radio"] {
        margin: 0;
        width: auto;
    }
}

fieldset {
    border: 1px solid $azul;
}

.contacto {
    h3, h4 {
        color: $azul;
    }
}