.header {
    &.obraN {
        background-color: $verde;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 20rem;
    top: 0;
    background-color: $verde;

    @include tablet {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        z-index: 100;
        position: fixed;
        height: 12rem;

        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        background-color: $transparente-verde;
    }
}

/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;

    @include tablet {
        width: auto;
    }
}

/* Dropdown button */
.dropbtn {
    display: inline-block;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: block;
    position: relative;
    background-color: $blanco;

    @include tablet {
        display: none;
        position: absolute;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border: 1px solid $azul;
        border-radius: 2rem;
        padding-left: 1rem;
    }
}

/* Dropdown content links */
.dropdown-content a {
    width: 100%;
    display: block;
}

/* Show the dropdown content when hovering over the dropdown button */
.dropdown:hover .dropdown-content {
    display: block;
}