.hero, .clientes, .nosotros, .experiencia, .servicios, .contacto, .portafolio {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    @include desktop {
        background-attachment: fixed;
    }
}

.hero {
    background-image: url(/build/img/imagen2.webp);
}

.clientes {
    background-image: url(/build/img/imagen1.webp);
    img {
        background-color: $transparente-blanco;
        width: 16rem;
    }
}

.nosotros {
    background-image: url(/build/img/imagen5.webp);

    p {
        background-color: $transparente-negroF;
        padding: 1rem 2rem;
    }
}

.experiencia {
    background-image: url(/build/img/construccion1.webp);
}

.servicios {
    background-image: url(/build/img/imagen8.webp);

    .contenedor {
        h2 {
            margin: 0;
        }
        h5 {
            font-size: 3rem;
        }
        gap: 2rem;
        margin-bottom: 2rem;
    }

}

.contacto {
    background-image: url(/build/img/imagen4.webp);
}

.portafolio {
    background-image: url(/build/img/imagen6.webp);
    .renglon {
        gap: 0;
    }
    .renglon:nth-child(odd) {
        flex-direction: row-reverse; 
    }

    .tarjeta:hover {
        a {
            color: $transparente;
        }
    }
}