@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Signika:wght@300..700&display=swap');

// Fuentes
$fuente_principal: 'Century Gothic', Arial, sans-serif;
// $fuente_secundaria: 'Poppins', sans-serif;
// $fuente_especial: 'Signika', serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 769px;
$desktop: 1024px;

// Colores
$azul: #00003d;
$azulClaro: #0080ff;
$negro: #000000;
$negroClaro: #111;
$blanco: #fff;
$blancoOscuro: #eee;
$gris: #222;
$grisClaro: #D3D3D3;
$rojo: rgb(189, 7, 7);
$verde: #aad9e5;
$verdeWA: #25D366;
$transparente-negro: rgb(0, 0, 0, 0.2);
$transparente-negroF: rgb(0, 0, 0, 0.5);
$transparente-verde: rgb(170, 217, 229, 0.7);
$transparente-blanco: rgba(255, 255, 255, 0.7);
$transparente: rgba(0, 0, 0, 0);

$separacion: 5rem;

// Fuentes

$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900