html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: $fuente_principal;
    font-size: 1.8rem;
    line-height: 1.8;
    background-color: $blanco;
    color: $blanco;
}

main {
    margin-top: 0;
}

p {
    text-align: justify;
}

.seccion {
    margin: 0;
    padding: 0;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.contenedor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin: 0 auto;
    padding: 4rem 0;
    width: 90%;
    max-width: 120rem;

    h4 {
        text-transform: none;
    }

    @include tablet {
        height: 60%;
        padding: 0 4rem;
        width: 90%;
    }
}

.renglon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    width: 100%;
    max-width: 120rem;
    gap: 2rem;

    @include tablet {
        flex-direction: row;
        justify-content: space-around;
    }
}

a {
    text-decoration: none;
}
a:link, a:visited {
    color: $blanco;
}

img {
    width: 100%;
    display: block;
}

h1, h2, h3, h4, h5 {
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    font-family: $fuente_principal;
    margin: 0 0 2rem 0;
    color: $blanco;
    max-width: 100%;
}

h1, h2 {
    font-size: 5rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
    @include tablet {
        font-size: 7.5rem;
    }
}

h3 {
    font-size: 3.5rem;
}
h4 {
    font-size: 3rem;
    text-transform: none;
}
h5 {
    font-size: 2.2rem;
    text-transform: none;
    font-weight: 500;
}