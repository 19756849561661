.grid2 {
    @include tablet {
        @include grid(2, 2rem);
    }
}

.grid111-container {
    display: flex;
    flex-direction: column;
    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
        gap: 20px; /* Gap between grid items */
    }
}

.grid11-container {
    display: flex;
    flex-direction: column;
    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Three equal-width columns */
        gap: 2rem; /* Gap between grid items */
    }
}

.grid32-container {
    display: flex;
    flex-direction: column;
    @include tablet {
        display: grid;
        grid-template-columns: 3fr 2fr; /* Three equal-width columns */
        gap: 2rem; /* Gap between grid items */
    }
}

.grid21-container {
    display: flex;
    flex-direction: column;
    @include tablet {
        display: grid;
        grid-template-columns: 2fr 1fr; /* Three equal-width columns */
        gap: 2rem; /* Gap between grid items */
    }
}

.grid23-container {
    display: flex;
    flex-direction: column;
    @include tablet {
        display: grid;
        grid-template-columns: 2fr 3fr; /* Three equal-width columns */
        gap: 2rem; /* Gap between grid items */
    }
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 9/16 aspect ratio */
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

//Tarjetas
.tarjeta {
    width: 80%;
    height: 20rem;
    position: relative;
    z-index: 1;
}

.tarjeta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 2;
}
.tarjeta:nth-child(odd)::before {
    background-color: rgba(0, 0, 61, 0.5); 
}

.tarjeta:nth-child(even)::before {
    background-color: rgba(0, 0, 0, 0.5); 
}

.tarjeta:hover::before {
    background-color: rgba(0, 0, 0, 0);
}

.tarjeta img {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tarjeta-contenido {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}