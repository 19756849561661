.navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    @include tablet {
        flex-direction: row;
        justify-content:center;
        align-items: center;

        max-width: 120rem;
        height: 90%;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;

        img {
            margin: 0 auto;
        }

        @include telefono {
            justify-content: flex-start;
            width: 40%;
            img {
                margin: 0 auto;
            }
        }

        @include tablet {
            justify-content: flex-start;
            width: 20%;
            img {
                margin: 0 auto;
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet {
            flex-direction: row;
            justify-content:flex-end;
            align-items: center;
            width: 80%;
        }
    }
}

.navegacion {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    transition: visibility 0s ease-in, opacity .5s linear, height .5s linear; 
    z-index: 11;
    border: 1px solid $azul;
    border-radius: 2rem;

    @include tablet {
        display: flex;
        justify-content: flex-end;
        opacity: 1;
        height: auto;
        width: 100%;
        visibility: visible;
        border: 0;
        border-radius: 0;
    }
    
    a {
        color: $gris;
        text-decoration: none;
        font-size: 1.8rem;
        display: block;
        text-align: center;
        padding: 1.5rem 0;

        @include tablet {
            margin-right: 2rem;
            font-size: 1.8rem;
            border-top: 0;
            font-weight: 500;
            text-align: left;
        }

        &:hover {
            color: $azul;
        }
    }

    .menu-item {
        font-weight: 600;
        text-transform: uppercase;
    }
}


/** Menu de hamburguesa **/
.mobile-menu {
    margin: 0 auto;
    position: relative;

    @include tablet {
        display: none;
    }

    img {
        width: 4rem;
    }
}

.mostrar {
    visibility: visible;
    opacity: 1;
    height: auto;
    position: absolute;
    top: 18rem;
    background-color: $blancoOscuro;
    width: 100%;
}