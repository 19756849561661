.obra {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
    width: 100%;

    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Three equal-width columns */
        gap: 2rem; /* Gap between grid items */
        
        margin-top: 12rem;
    }

    .obra-container, .obra-container-contenido {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 0;

        img {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: left;
        }
        h3, p {
            color: $azul;
            text-align: center;
        }

        @include tablet {
            height: 100vh;
        }
    }
    .obra-container-contenido {
        max-width: 90%;
        height: 100%;

        .galeria {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            img {
                max-width: 30rem;
            }
        }

        @include tablet {
            max-width: 100%;

            .galeria {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                gap: 1rem;
            }
        }
    }
}